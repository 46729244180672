@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* finbricks/ui */
@import "@finbricks/ui/src/ui.css";

@layer base {
  body,
  #__next {
    @apply min-h-screen bg-light-25;
  }

  #__next {
    @apply flex flex-col;
  }
}

/**
 Site Logo
 */
header svg.app-logo {
  @apply h-8 w-auto;
}
footer svg.app-logo {
  @apply fill-light-25 h-12 w-auto
}

footer svg.app-logo path {
  @apply fill-light-25;
}

/* Layout utils */
@layer components {
  .c-app-row {
    @apply mx-auto w-full max-w-full lg:px-app--outer px-4;
  }
  .c-app-row--lg {
    max-width: calc(theme('width.app-lg') + 2 * theme('spacing.app--outer'));
  }
  .c-app-row--md {
    max-width: calc(theme('width.app-md') + 2 * theme('spacing.app--outer'));
  }
}


/**
Cookie consent customization css
 */
.finbricks_cookie_theme {
  --cc-bg: theme('colors.light.0');
  --cc-text: theme('colors.dark.75');
  --cc-btn-primary-bg: theme('colors.brand.50');
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: theme('colors.brand.25');;
  --cc-btn-secondary-bg: theme('colors.brand.0');
  --cc-btn-secondary-text: theme('colors.brand.75');;
  --cc-btn-secondary-hover-bg: #d3ccf3;
  --cc-toggle-bg-off: theme('colors.light.75');;
  --cc-toggle-bg-on: theme('colors.brand.50');
  --cc-toggle-bg-readonly: theme('colors.light.50');
  --cc-toggle-knob-bg: theme('colors.light.0');
  --cc-toggle-knob-icon-color: theme('colors.light.25');
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: theme('colors.brand.0');
  --cc-cookie-category-block-bg-hover: #d3ccf3;
  --cc-section-border: theme('colors.light.50');
  --cc-cookie-table-border: theme('colors.light.50');
  --cc-overlay-bg: rgba(234, 232, 251, .85);
  --cc-webkit-scrollbar-bg: #ebeff9;
  --cc-webkit-scrollbar-bg-hover: theme('colors.brand.50');
}

.finbricks_cookie_theme #c-ttl{
  color: var(--cc-btn-primary-bg);
}

/* Custom border radius */
.finbricks_cookie_theme #cm,
.finbricks_cookie_theme #s-bl .act .b-acc,
.finbricks_cookie_theme #s-inr,
.finbricks_cookie_theme .cc_div .b-tl,
.finbricks_cookie_theme .cc_div .c-bl{
  border-radius: theme('borderRadius.1');
}

.finbricks_cookie_theme .cc_div .c-bn{
  border-radius: theme('borderRadius.1');
}
