@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }

  body {
    @apply text-dark-50;
  }

  body,
  #root {
    @apply min-h-screen;
  }

  #root {
    @apply flex flex-col;
  }
}

@layer components {
  /*
  UI - Text Field (input, textarea, select)
  */
  .ui-text-field {
    --ui-text-field--icon-size: 0; /* Select arrow */
    --ui-text-field--padding-x: inherit;
    @apply flex flex-col gap-y-1;
  }
  .ui-text-field .ui-text-field__element {
    @apply border w-full;
    padding-left: var(--ui-text-field--padding-x);
    padding-right: var(--ui-text-field--padding-x);
  }

  /* disabled/readonly */
  .ui-text-field.ui-text-field--disabled .ui-text-field__label {
    @apply text-light-50;
  }
  .ui-text-field.ui-text-field--disabled .ui-text-field__element,
  .ui-text-field.ui-text-field--disabled .ui-text-field__element {
    @apply border-light-50 bg-light-25;
    @apply placeholder-light-50;
  }

  /* shared for touchable states */
  .ui-text-field:not(.ui-text-field--disabled) .ui-text-field__element {
    @apply bg-light-0 text-dark-50 placeholder-light-100;
    @apply appearance-none focus:outline-none focus:ring-1;
  }

  /* state: base */
  .ui-text-field.ui-text-field--base .ui-text-field__element {
    @apply border-light-75;
    @apply hover:border-brand-25;
    @apply focus:border-brand-25 focus:ring-brand-25;
  }

  /* state: negative */
  .ui-text-field.ui-text-field--negative .ui-text-field__label {
    @apply text-red-50;
  }
  .ui-text-field.ui-text-field--negative .ui-text-field__element {
    @apply border-red-50;
    @apply hover:border-red-50;
    @apply focus:border-red-50 focus:ring-red-50;
  }

  /* size: large */
  .ui-text-field.ui-text-field--large .ui-text-field__label {
    @apply typo-md--bold;
  }
  .ui-text-field.ui-text-field--large .ui-text-field__element {
    --ui-text-field--padding-x: 1rem;
    @apply typo-md rounded-2xl py-4;
  }

  /* size: medium */
  .ui-text-field.ui-text-field--medium .ui-text-field__label {
    @apply typo-md--bold;
  }
  .ui-text-field.ui-text-field--medium .ui-text-field__element {
    --ui-text-field--padding-x: 1rem;
    @apply typo-sm rounded-lg py-2.5;
  }

  /* textarea */
  .ui-text-field textarea {
    resize: none;
  }
  .ui-text-field.ui-text-field--large textarea {
    @apply h-[6.5rem] max-h-[6.5rem]
  }
  .ui-text-field.ui-text-field--medium textarea {
    @apply h-16 max-h-16
  }
  /* specific for select element */
  .ui-text-field select.ui-text-field__element {
    --ui-text-field--icon-size: 0.875rem;
    /* IntelliJ IDEs can notice you about error with the url value. Ignore it, the value is correct and IDE contains bug */
    /* issue: https://youtrack.jetbrains.com/issue/WEB-56089/CSS-Avoid-file-reference-errors-in-http-data-URLs-in-webpack-projects */
    /* noinspection CssUnknownTarget */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23393939' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-position: calc(100% - var(--ui-text-field--padding-x));
    background-size: var(--ui-text-field--icon-size);
    background-repeat: no-repeat;
    padding-right: calc(var(--ui-text-field--padding-x) * 1.5 + var(--ui-text-field--icon-size));
  }

  .ui-text-field.ui-text-field--medium select.ui-text-field__element {
    background-size: 18px;
  }

  /*
  * UI BUTTON
   */
  .ui-button {
    --button-bg-color: initial;
    --button-bg-color-hover: initial;
    --button-bg-color-active: initial;
    --button-text-color: initial;
    --button-text-color-hover: initial;
    --button-text-color-active: initial;
    --button-outline-color-focus: initial;

    --button-padding: initial;
    --button-height: initial;
    --button-width: initial;

    --button-icon-size: initial;

    @apply relative overflow-hidden inline-flex items-center justify-center gap-x-2 text-center transition-colors;
    color: var(--button-text-color);
    background-color: var(--button-bg-color);
    min-height: var(--button-height);
  }

  .ui-button:hover {
    color: var(--button-text-color-hover);
    background-color: var(--button-bg-color-hover);
  }
  .ui-button:active {
    color: var(--button-text-color-active);
    background-color: var(--button-bg-color-active);
  }

  .ui-button:not(.ui-button--is-square) {
    padding: var(--button-padding);
    min-width: var(--button-width);
  }

  .ui-button__icon {
    @apply shrink-0;
    width: var(--button-icon-size);
    height: var(--button-icon-size);
  }

  /* States */
  .ui-button-is-reversed {
    @apply flex-row-reverse;
  }

  .ui-button--is-square {
    min-width: var(--button-height);
    min-height: var(--button-height);
  }

  .ui-button--is-disabled {
    @apply pointer-events-none;
  }

  /* Sizes */
  .ui-button--large {
    --button-icon-size: 1.25rem;
    @apply typo-md--bold;
  }
  .ui-button--medium {
    --button-icon-size: 1.125rem;
    @apply typo-sm--bold;
  }

  /* Variants */
  /* variant - primary */
  .ui-button--primary {
    --button-text-color: theme("colors.light.0");
    --button-text-color-hover: theme("colors.light.0");
    --button-text-color-active: theme("colors.light.0");
  }
  .ui-button--primary:focus-visible {
    @apply outline-none ring-4 ring-[var(--button-outline-color-focus)];
  }

  .ui-button--primary.ui-button--base {
    --button-bg-color: theme("colors.brand.50");
    --button-bg-color-hover: theme("colors.brand.25");
    --button-bg-color-active: theme("colors.brand.75");
    --button-outline-color-focus: theme("colors.brand.0");
  }
  .ui-button--primary.ui-button--base.ui-button--is-disabled {
    --button-bg-color: theme("colors.light.50");
    --button-text-color: theme("colors.light.100");
  }
    /* variant - primary - sizes */
  .ui-button--primary.ui-button--large {
    --button-padding: 1rem 2rem;
    --button-height: 3rem;
    --button-width: 8.75rem;
    border-radius: 1rem;
  }
  .ui-button--primary.ui-button--medium {
    --button-padding: 0.5rem 1.5rem;
    --button-height: 2.55rem;
    --button-width: 7.5rem;
    border-radius: 0.5rem;
  }

  /* variant - text */
  .ui-button--text {
    --button-width: initial;
    --button-height: initial;
    --button-padding: initial;
    --button-text-color-hover: var(--button-text-color);
    --button-outline-color-focus: var(--button-text-color-hover);
    border-radius: 0.125rem;
    @apply gap-1;
  }

  .ui-button--text:hover {
    @apply underline underline-offset-2;
  }

  .ui-button--text:focus-visible {
    outline-color: var(--button-outline-color-focus);
    outline-offset: 4px;
  }

  .ui-button--text.ui-button--base {
    --button-text-color: theme("colors.brand.50");
    --button-text-color-active: theme("colors.brand.75");
  }
  .ui-button--text.ui-button--base.ui-button--is-disabled {
    --button-text-color: theme("colors.light.75");
  }

  /* Loading animation */
  .ui-loader {
    background: inherit;
    @apply absolute inset-0 inline-flex items-center justify-center gap-x-2;
  }

  .ui-loader > * {
    @apply h-4 w-4 rounded bg-brand-25;
    --ui-loader-scale: 1.25;
    animation: loader 1.5s ease-in-out infinite;
  }
  .ui-loader *:nth-child(1) {
    animation-delay: 0.25s;
  }
  .ui-loader *:nth-child(2) {
    animation-delay: 0.5s;
  }
  .ui-loader *:nth-child(3) {
    animation-delay: 0.75s;
  }
  @keyframes loader {
    17% {
      transform: scale(1);
    }
    33% {
      transform: scale(var(--ui-loader-scale));
      background: theme("colors.brand.0");
    }
  }

  /* Loading variants */
  .ui-button--medium .ui-loader > * {
    @apply h-3.5 w-3.5;
    --ui-loader-scale: 1.3;
  }
  .ui-button--small .ui-loader > * {
    @apply h-3 w-3;
    --ui-loader-scale: 1.2;
  }

  /*
  * UI TOGGLE
  * .element (input) is hidden from the view because it doesn't support pseudo elements like after.
  * The element is replaced in the view by shadow-element (div).
  */
  .ui-toggle {
    --ui-toggle-width: 0rem;
    --ui-toggle-height: 0rem;
    --ui-toggle-dot-size: calc(var(--ui-toggle-height) - (2 * var(--ui-toggle-dot-offset)));
    --ui-toggle-dot-offset: 0rem;

    --ui-toggle-checked-icon-size: initial;
    --ui-toggle-element-offset: 0rem;
    --ui-toggle-gap: initial;

    @apply relative inline-flex items-start;
    gap: var(--ui-toggle-gap);
  }

  .ui-toggle__element {
    @apply sr-only;
  }
  .ui-toggle__shadow-element {
    width: var(--ui-toggle-width);
    height: var(--ui-toggle-height);
    top: var(--ui-toggle-element-offset);
    @apply relative shrink-0 rounded-full transition-colors;
    /* dot element */
    @apply after:absolute after:block after:rounded-full after:transition-all;
    @apply after:h-[var(--ui-toggle-dot-size)] after:w-[var(--ui-toggle-dot-size)];
    @apply after:top-[var(--ui-toggle-dot-offset)] after:bottom-[var(--ui-toggle-dot-offset)] after:left-[var(--ui-toggle-dot-offset)];
    @apply after:shadow-sm;
  }
  .ui-toggle__element:checked ~ .ui-toggle__shadow-element {
    /* To make animation work, we calculate left value instead of right value.  */
    @apply after:left-[calc(var(--ui-toggle-width)_-_var(--ui-toggle-dot-size)_-_var(--ui-toggle-dot-offset))];
  }

  .ui-toggle__element:focus-visible ~ .ui-toggle__shadow-element {
    @apply outline-none ring-4;
  }

  /* Sizes */
  .ui-toggle--medium {
    --ui-toggle-width: 2.75rem;
    --ui-toggle-height: 1.5rem;
    --ui-toggle-dot-offset: 0.125rem;
    --ui-toggle-checked-icon-size: 0.375rem;
    --ui-toggle-gap: 0.5rem;
    --ui-toggle-element-offset: 0.25rem;
  }
  .ui-toggle--medium .ui-toggle__label {
    @apply typo-md;
  }
  /* States (are not applied if the toggle is disabled */
  /* state: base */
  .ui-toggle--base .ui-toggle__shadow-element {
    @apply bg-light-50;
    @apply after:bg-light-0;
  }
  .ui-toggle--base .ui-toggle__element:focus-visible ~ .ui-toggle__shadow-element {
    @apply ring-brand-0;
  }
  .ui-toggle--base:hover .ui-toggle__shadow-element {
    @apply bg-light-75;
  }
  .ui-toggle--base .ui-toggle__element:checked ~ .ui-toggle__shadow-element {
    @apply bg-brand-25;
  }
  .ui-toggle--base .ui-toggle__element:checked ~ .ui-toggle__label {
    @apply text-brand-25;
  }
  .ui-toggle--base:hover .ui-toggle__element:checked ~ .ui-toggle__shadow-element {
    @apply bg-brand-50;
  }
  .ui-toggle--base:hover .ui-toggle__element:checked ~ .ui-toggle__label {
    @apply text-brand-50;
  }

  /* state: negative */
  .ui-toggle--negative .ui-toggle__shadow-element {
    @apply bg-red-25 focus-visible:ring-red-0;
    @apply after:bg-light-0;
  }
  .ui-toggle--negative .ui-toggle__element:focus-visible ~ .ui-toggle__shadow-element {
    @apply ring-red-0;
  }
  .ui-toggle--negative .ui-toggle__label {
    @apply text-red-50;
  }
  .ui-toggle--negative .ui-toggle__element:checked ~ .ui-toggle__shadow-element {
    @apply bg-red-50;
  }

  /* pseudo-state: disabled */
  .ui-toggle--disabled .ui-toggle__shadow-element {
    @apply bg-light-50 after:bg-light-25;
  }
  .ui-toggle--disabled .ui-toggle__label {
    @apply text-light-50;
  }
  .ui-toggle--disabled .ui-toggle__element:checked ~ .ui-toggle__label {
    @apply text-light-75;
  }

  /* ui language switch */
  .ui-language-switch {
    @apply outline-0;
  }
  .ui-language-switch:not(:active):hover {
    @apply opacity-75
  }

  .ui-language-switch:active > svg {
    @apply brightness-95
  }

  .ui-language-switch:not(:active):focus > svg, .ui-language-switch:not(:active):focus-visible > svg {
    filter: drop-shadow(2px 0 0 theme("colors.brand.0")) drop-shadow(-2px 0 0 theme("colors.brand.0")) drop-shadow(0 2px 0 theme("colors.brand.0")) drop-shadow(0 -2px 0 theme("colors.brand.0"));
  }
}

/* ui cards */
  .ui-card {
    @apply rounded-1 shadow-lg bg-light-0;
  }
  .ui-card__content {
    @apply p-6 lg:p-12
  }
  .ui-card--second {
    @apply shadow-md;
  }

  .ui-card--third {
    @apply shadow-xl;
  }
